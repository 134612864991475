import { Component, Mixins, Watch } from "vue-property-decorator";
import AnalysisBase from "@/models/analysis/analysis-base";
import UlContentHeader from "@/components/UlContentHeader.vue";
import UIDatePicker from "@/components/UIDatePicker.vue";
import Flash, { ErrorAlert } from "@/store/common/flash";
import ShopGet from "@/store/shop/get";
import AppDownloadChart from "@/views/analysis/AppDownloadChart.vue";
import AppDownloadProfileChart from "@/views/analysis/AppDownloadProfileChart.vue";
import AppDownloadGet from "@/store/analysis/app-download/get";
import AppDownloadProfileTotalGet from "@/store/analysis/app-download-profile-total/get";
import { AppDownloadGetRequest } from "@/api/analysis/app-download/request";
import { AppDownloadProfileGetRequest } from "@/api/analysis/app-download-profile/request";
import Admin from "@/store/admin/admin";

export interface AppDownloadInputOptions {
  startDate: string;
  endDate: string;
  viewType: number;
  categoryType: number;
  selectShop: { id: string; name: string } | null;
}

@Component({
  components: {
    UlContentHeader,
    UIDatePicker,
    AppDownloadChart,
    AppDownloadProfileChart
  }
})
export default class AnalysisAppDownload extends Mixins(AnalysisBase) {
  // ------------
  // 固定値
  // ------------
  // タイトル
  headingMain = "ダウンロード数";
  headingSub = "Number of downloads";
  breadCrumbs = [{ text: "ダウンロード数", disabled: true }];

  menuProps = {
    closeOnClick: false,
    closeOnContentClick: false,
    disableKeys: true,
    openOnClick: false,
    maxHeight: 304
  };

  viewTypes = [
    { label: "日別", value: 1 },
    { label: "月別", value: 2 }
  ];

  tabItems = [{ title: "DLユーザー数" }, { title: "アクティブユーザー数" }];

  // ------------
  // 変動値
  // ------------
  // ローディングステータス
  isLoadedShopData = false;
  isLoadedAppDownloadData = false;
  isLoadedAppDownloadProfileData = false;

  // DatePickerの表示フラグ
  startDateModal = null;
  endDateModal = null;

  // 検索入力オプション
  appDownloadInputOptions: AppDownloadInputOptions = {
    startDate: this.initDates[0],
    endDate: this.initDates[1],
    viewType: 1,
    categoryType: 1,
    selectShop: null
  };

  appDownloadProfileInputOptions: AppDownloadProfileGetRequest = {
    startDate: this.appDownloadInputOptions.startDate,
    endDate: this.appDownloadInputOptions.endDate,
    date: null,
    shopId: null as number | null,
    viewType: this.appDownloadInputOptions.viewType,
    categoryType: this.appDownloadInputOptions.categoryType,
    type: 1
  };

  totalCountOnDrillDowned = 0;

  @Watch("activeTab")
  watchActiveTab(newTabIndex: number) {
    this.appDownloadInputOptions.categoryType = newTabIndex + 1;
    this.appDownloadProfileInputOptions.categoryType = newTabIndex + 1;
  }

  /**
   * 総件数
   */
  get totalCount() {
    return this.totalCountOnDrillDowned > 0
      ? this.totalCountOnDrillDowned
      : AppDownloadGet.getTotalCount;
  }

  get totalCountSuffix() {
    const suffixes = ["DL", "人"];
    return suffixes[this.activeTab];
  }

  get appDownloadData() {
    return AppDownloadGet.getData;
  }

  get appDownloadProfileData() {
    return AppDownloadProfileTotalGet.getResult;
  }

  get startDateForView() {
    return this.replaceHyphenToSlash(this.appDownloadInputOptions.startDate);
  }
  set startDateForView(date) {
    this.appDownloadInputOptions.startDate = this.replaceSlashToHyphen(date);
  }

  get endDateForView() {
    return this.replaceHyphenToSlash(this.appDownloadInputOptions.endDate);
  }
  set endDateForView(date) {
    this.appDownloadInputOptions.endDate = this.replaceSlashToHyphen(date);
  }

  get categoryType() {
    return this.activeTab;
  }

  get activeTab() {
    return this.$route.query.aad_tab ? Number(this.$route.query.aad_tab) : 0;
  }

  set activeTab(val) {
    let query = { ...this.$route.query };
    query.aad_tab = String(val);
    this.$router.replace({ query: query }).then();
  }

  get shopItems() {
    return ShopGet.getItems;
  }

  get initDates() {
    const date = new Date(),
      y = date.getFullYear(),
      m = date.getMonth();
    const firstDate = new Date(y, m, 1);
    const lastDate = new Date(y, m + 1, 0);
    return [this.stringFromDate(firstDate), this.stringFromDate(lastDate)];
  }

  /**
   * createdライフサイクルフック
   */
  async created() {
    if (this.editFocused || !this.isNeededActiveOnDashBoard) {
      return false;
    }

    await this.fetchShopList();
    this.watchActiveTab(this.activeTab);
    if (!Admin.isAdmin) {
      this.appDownloadInputOptions.selectShop = ShopGet.getItems[0];
      await this.fetchRootChartData();
    }
  }

  /**
   * beforeDestroyライフサイクルフック
   */
  async beforeDestroy() {
    await ShopGet.clearResponse();
    await AppDownloadGet.clearResponse();
  }

  async fetchRootChartData() {
    this.isLoadedAppDownloadData = false;
    this.isLoadedAppDownloadProfileData = false;
    await this.fetchAppDownload();
    await this.fetchAppDownloadProfile();
  }

  /**
   * 店舗情報の一覧を取得する処理
   */
  async fetchShopList(): Promise<boolean> {
    this.isLoadedShopData = false;
    await ShopGet.get();
    if (!ShopGet.isSuccess) {
      await Flash.setErrorNow({
        message: ShopGet.getMessage,
        showReloadButton: false
      } as ErrorAlert);
    }
    this.isLoadedShopData = true;
    return ShopGet.isSuccess;
  }

  /**
   * グラフデータを取得する処理
   */
  async fetchAppDownload(): Promise<boolean> {
    this.isLoadedAppDownloadData = false;
    await Flash.clear();
    const appDownloadGetRequest: AppDownloadGetRequest = {
      startDate: this.startDateForView,
      endDate: this.endDateForView,
      viewType: this.appDownloadInputOptions.viewType,
      categoryType: this.appDownloadInputOptions.categoryType,
      searchShopId:
        this.appDownloadInputOptions.selectShop == null
          ? null
          : Number(this.appDownloadInputOptions.selectShop.id)
    };
    await AppDownloadGet.get(appDownloadGetRequest);
    if (!AppDownloadGet.isSuccess) {
      await Flash.setErrorNow({
        message: AppDownloadGet.getMessage,
        showReloadButton: false
      } as ErrorAlert);
    }
    this.isLoadedAppDownloadData = true;
    return AppDownloadGet.isSuccess;
  }

  async fetchAppDownloadProfile(): Promise<boolean> {
    this.isLoadedAppDownloadProfileData = false;
    await Flash.clear();
    const appDownloadProfileGetRequest: AppDownloadProfileGetRequest = {
      startDate: this.startDateForView,
      endDate: this.endDateForView,
      date: this.appDownloadProfileInputOptions.date,
      shopId:
        this.appDownloadInputOptions.selectShop == null
          ? null
          : Number(this.appDownloadInputOptions.selectShop.id),
      viewType: this.appDownloadInputOptions.viewType,
      categoryType: this.appDownloadProfileInputOptions.categoryType,
      type: this.appDownloadProfileInputOptions.type
    };
    await AppDownloadProfileTotalGet.get(appDownloadProfileGetRequest);
    if (!AppDownloadProfileTotalGet.isSuccess) {
      await Flash.setErrorNow({
        message: AppDownloadProfileTotalGet.getMessage,
        showReloadButton: false
      } as ErrorAlert);
    }
    this.isLoadedAppDownloadProfileData = true;
    return AppDownloadProfileTotalGet.isSuccess;
  }

  updateTotalCountOnDrillDowned(value: number) {
    this.totalCountOnDrillDowned = value;
  }
}
