import service from "@/api/service";
import { AppDownloadProfileGetRequest } from "@/api/analysis/app-download-profile/request";
import { AppDownloadProfileGetResponse } from "@/api/analysis/app-download-profile/response";

/**
 * プロフィール属性(アプリDL)APIをコールします。
 *
 * @param getRequest プロフィール属性(アプリDL)APIのリクエストボディ
 * @return AppDownloadProfileGetResponse
 */
export async function get(getRequest: AppDownloadProfileGetRequest) {
  const response = await service.post(
    "/analysis-app-download-profile",
    getRequest
  );
  return response.data as AppDownloadProfileGetResponse;
}
