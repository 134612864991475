import service from "@/api/service";
import { AppDownloadGetRequest } from "@/api/analysis/app-download/request";
import { AppDownloadGetResponse } from "@/api/analysis/app-download/response";

/**
 * アプリDLAPIをコールします。
 *
 * @param getRequest アプリDLAPIのリクエストボディ
 * @return AppDownloadGetResponse
 */
export async function get(getRequest: AppDownloadGetRequest) {
  const response = await service.post("/analysis-app-download", getRequest);
  return response.data as AppDownloadGetResponse;
}
