import { Component, Mixins, Prop, Vue, Watch } from "vue-property-decorator";
import UlPieChart from "@/models/analysis/ul-pie-chart";
import AnalysisBase, { ChartElement } from "@/models/analysis/analysis-base";
import { AppDownloadProfileGetResult } from "@/api/analysis/app-download-profile/response";
import { AppDownloadUserCSVGetRequest } from "@/api/analysis/app-download-user-csv/request";
import AppDownloadProfileTotalGet from "@/store/analysis/app-download-profile-total/get";
import * as AppDownloadUserCSVGet from "@/api/analysis/app-download-user-csv";
import { isSuccess } from "@/api/response";
import Flash, { ErrorAlert } from "@/store/common/flash";
import { AppDownloadInputOptions } from "@/models/analysis/app-download";

@Component({
  components: {
    UlPieChart: UlPieChart
  }
})
export default class AnalysisAppDownloadProfileChart extends Mixins(
  AnalysisBase
) {
  @Prop() data!: AppDownloadProfileGetResult;
  @Prop() inputOptions!: AppDownloadInputOptions;

  get genderChartData() {
    return this.getPieChartData(AppDownloadProfileTotalGet.getGenderData);
  }

  get genderChartOptions() {
    return this.getPieChartOptions(
      AppDownloadProfileTotalGet.getGenderData,
      this.fetchAppDownloadUserCSVByGender
    );
  }

  get periodChartData() {
    return this.getPieChartData(AppDownloadProfileTotalGet.getPeriodData);
  }

  get periodChartOptions() {
    return this.getPieChartOptions(
      AppDownloadProfileTotalGet.getPeriodData,
      this.fetchAppDownloadUserCSVByPeriod
    );
  }

  get profile1ChartData() {
    return this.getPieChartData(AppDownloadProfileTotalGet.getProfile1Data);
  }

  get profile1ChartOptions() {
    return this.getPieChartOptions(
      AppDownloadProfileTotalGet.getProfile1Data,
      this.fetchAppDownloadUserCSVByProfile1
    );
  }

  get profile2ChartData() {
    return this.getPieChartData(AppDownloadProfileTotalGet.getProfile2Data);
  }

  get profile2ChartOptions() {
    return this.getPieChartOptions(
      AppDownloadProfileTotalGet.getProfile2Data,
      this.fetchAppDownloadUserCSVByProfile2
    );
  }

  get profile3ChartData() {
    return this.getPieChartData(AppDownloadProfileTotalGet.getProfile3Data);
  }

  get profile3ChartOptions() {
    return this.getPieChartOptions(
      AppDownloadProfileTotalGet.getProfile3Data,
      this.fetchAppDownloadUserCSVByProfile3
    );
  }

  get profile4ChartData() {
    return this.getPieChartData(AppDownloadProfileTotalGet.getProfile4Data);
  }

  get profile4ChartOptions() {
    return this.getPieChartOptions(
      AppDownloadProfileTotalGet.getProfile4Data,
      this.fetchAppDownloadUserCSVByProfile4
    );
  }

  get profile5ChartData() {
    return this.getPieChartData(AppDownloadProfileTotalGet.getProfile5Data);
  }

  get profile5ChartOptions() {
    return this.getPieChartOptions(
      AppDownloadProfileTotalGet.getProfile5Data,
      this.fetchAppDownloadUserCSVByProfile5
    );
  }

  /**
   * createdライフサイクルフック
   */
  async created() {}

  /**
   * beforeDestroyライフサイクルフック
   */
  async beforeDestroy() {}

  async fetchAppDownloadUserCSV(
    graphType: number,
    profileType: number,
    elementIndex: number
  ) {
    const appDownloadUserCSVGetRequest: AppDownloadUserCSVGetRequest = {
      searchShopId:
        this.inputOptions.selectShop != null
          ? Number(this.inputOptions.selectShop.id)
          : null,
      startDate: this.inputOptions.startDate,
      endDate: this.inputOptions.endDate,
      viewType: this.inputOptions.viewType,
      categoryType: this.inputOptions.categoryType,
      graphType: graphType,
      profileType: profileType
    };

    if (graphType == 1) {
      appDownloadUserCSVGetRequest.profileData =
        AppDownloadProfileTotalGet.getResult.profile1Data.data.labels[
          elementIndex
        ];
    } else if (graphType == 2) {
      appDownloadUserCSVGetRequest.profileData =
        AppDownloadProfileTotalGet.getResult.profile2Data.data.labels[
          elementIndex
        ];
    } else if (graphType == 3) {
      appDownloadUserCSVGetRequest.profileData =
        AppDownloadProfileTotalGet.getResult.profile3Data.data.labels[
          elementIndex
        ];
    } else if (graphType == 4) {
      appDownloadUserCSVGetRequest.profileData =
        AppDownloadProfileTotalGet.getResult.profile4Data.data.labels[
          elementIndex
        ];
    } else if (graphType == 5) {
      appDownloadUserCSVGetRequest.profileData =
        AppDownloadProfileTotalGet.getResult.profile5Data.data.labels[
          elementIndex
        ];
    } else if (graphType == 6) {
      appDownloadUserCSVGetRequest.profileData =
        AppDownloadProfileTotalGet.getResult.genderData.data.labels[
          elementIndex
        ];
    } else if (graphType == 7) {
      appDownloadUserCSVGetRequest.profileData =
        AppDownloadProfileTotalGet.getResult.periodData.data.labels[
          elementIndex
        ];
    }

    const appDownloadUserCSVGetResponse = await AppDownloadUserCSVGet.get(
      appDownloadUserCSVGetRequest
    );

    if (
      appDownloadUserCSVGetResponse &&
      appDownloadUserCSVGetResponse.statusCd !== 200
    ) {
      await Flash.setErrorNow({
        message: appDownloadUserCSVGetResponse.message,
        showReloadButton: false
      } as ErrorAlert);
      return false;
    }

    this.saveCSV(
      appDownloadUserCSVGetResponse.results.csvString,
      appDownloadUserCSVGetResponse.results.fileName
    );
  }

  async fetchAppDownloadUserCSVByProfile1(
    event?: MouseEvent | undefined,
    activeElements?: Array<{}> | undefined
  ) {
    this.fetchAppDownloadUserCSVByProfile(1, 1, event, activeElements);
  }

  async fetchAppDownloadUserCSVByProfile2(
    event?: MouseEvent | undefined,
    activeElements?: Array<{}> | undefined
  ) {
    this.fetchAppDownloadUserCSVByProfile(2, 1, event, activeElements);
  }

  async fetchAppDownloadUserCSVByProfile3(
    event?: MouseEvent | undefined,
    activeElements?: Array<{}> | undefined
  ) {
    this.fetchAppDownloadUserCSVByProfile(3, 1, event, activeElements);
  }

  async fetchAppDownloadUserCSVByProfile4(
    event?: MouseEvent | undefined,
    activeElements?: Array<{}> | undefined
  ) {
    this.fetchAppDownloadUserCSVByProfile(4, 1, event, activeElements);
  }

  async fetchAppDownloadUserCSVByProfile5(
    event?: MouseEvent | undefined,
    activeElements?: Array<{}> | undefined
  ) {
    this.fetchAppDownloadUserCSVByProfile(5, 1, event, activeElements);
  }

  async fetchAppDownloadUserCSVByGender(
    event?: MouseEvent | undefined,
    activeElements?: Array<{}> | undefined
  ) {
    this.fetchAppDownloadUserCSVByProfile(6, 1, event, activeElements);
  }

  async fetchAppDownloadUserCSVByPeriod(
    event?: MouseEvent | undefined,
    activeElements?: Array<{}> | undefined
  ) {
    this.fetchAppDownloadUserCSVByProfile(7, 1, event, activeElements);
  }

  async fetchAppDownloadUserCSVByProfile(
    graphType: number,
    profileType: number,
    event?: MouseEvent | undefined,
    activeElements?: Array<{}> | undefined
  ) {
    if (
      !event ||
      !activeElements ||
      (activeElements && activeElements.length <= 0)
    ) {
      return;
    }

    if (event.ctrlKey || event.shiftKey) {
      const element = activeElements[0] as ChartElement;
      await this.fetchAppDownloadUserCSV(
        graphType,
        profileType,
        element._index
      );
    }
  }
}
